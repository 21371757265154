<template>
  <b-card>
    <modal-project
      :client-data="clientData"
      :application-options="applicationOptions"
      :document-type-options="documentTypeOptions"
      :projectstage-options="projectstageOptions"
      :servicecode-options="servicecodeOptions"
      :contract-options="contracts"
      @refetch-data="refetchData"
    />
    <modal-contract
      :contract-data="contractData"
      :clientOptions="clientOptions"
      :userOptions="userOptions"
      :template-options="templateOptions"
      @refetch-data="refetchData"
    />

    <client-header
      :client-data="clientData"
      :client-options="clientOptions"
      @refetch-data="refetchData"
    />
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">客戶基本資料</span>
        </template>
        <!-- <client-information  :client-data="clientData" :user-options="userOptions" :groupcode-options="groupcodeOptions" @refetch-data="refetchData" /> -->
        <client-edit-tab-information
          :groupcode-data="groupcodeOptions"
          :user-options="userOptions"
          :client-data="clientData"
          @refetch-data="refetchData"
        />
      </b-tab>
      <!--Tab: Project List -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ListIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">項目列表</span>
        </template>
        <project-list
          :project-data="projectData"
          :table-project-columns="tableProjectColumns"
          :application-options="applicationOptions"
          :projectstage-options="projectstageOptions"
          :document-type-options="documentTypeOptions"
          :contract-options="contracts"
          @refetch-data="refetchData"
        />
      </b-tab>

      <!--Tab: Contract -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">合約</span>
        </template>
        <contract-list
          :contracts="contracts"
          :table-contract-columns="tableContractColumns"
          @refetch-data="refetchData"
        />
      </b-tab>

      <!--Tab: Invoice -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="FileIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">發票</span>
        </template>
        <invoice-list
          :invoice-data="invoiceData"
          :table-invoice-columns="tableInvoiceColumns"
          :payment-options="paymentOptions"
          :receipt-template-options="receiptTemplateOptions"
          @refetch-data="refetchData"
        />
      </b-tab>

      <!--Tab: Quotation -->
      <!--   <b-tab lazy>
                <template #title>
                <feather-icon icon="FileIcon" size="16" class="mr-0 mr-sm-50"/>
                <span class="d-none d-sm-inline">報價單</span>
                </template> 
                <quotation-list :quotation-data="quotationData" :table-quotation-columns="tableQuotationColumns" :payment-options="paymentOptions" @refetch-data="refetchData" />
            </b-tab>
 -->
      <!--Tab: Payment History -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="CreditCardIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">付款記錄</span>
        </template>
        <payment-list
          :payment-data="paymentData"
          :table-payment-columns="tablePaymentColumns"
          :payment-options="paymentOptions"
          :receipt-template-options="receiptTemplateOptions"
          @refetch-data="refetchData"
        />
      </b-tab>

      <!--Tab: File -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="CloudIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">客戶文件夾</span>
        </template>
        <filemanager
          v-if="clientCode != ''"
          :client-data="clientData"
          :client-code="clientCode"
        />
      </b-tab>
      <!--Tab: 日歷 -->
      <b-tab lazy>
        <template #title>
          <feather-icon icon="CalendarIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">日歷</span>
        </template>
        <calendar :client-id="clientData.id" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import filemanager from "./file/FileManager.vue";
import ClientInformation from "./clients-information/ClientsInformation.vue";
import clientStoreModule from "../clientStoreModule";
import ClientHeader from "./clients-header/ClientsHeader.vue";
import ModalProject from "./modal/ModalProject.vue";
import ModalContract from "./modal/ModalContract.vue";
import ProjectList from "./projects-list/ProjectsList.vue";
import InvoiceList from "./invoices-list/InvoicesList.vue";
import QuotationList from "./quotations-list/QuotationsList.vue";
import PaymentList from "./payments-list/PaymentsList.vue";
import ContractList from "./contracts-list/ContractsList.vue";

import useProjectsList from "./projects-list/useProjectsList";
import ClientEditTabInformation from "../clients-edit/ClientEditTabInformation.vue";

import Calendar from "./calendar/Calendar.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BButton,
    Calendar,
    filemanager,
    ClientInformation,
    ClientHeader,
    ProjectList,
    InvoiceList,
    QuotationList,
    ContractList,
    PaymentList,
    ModalProject,
    ModalContract,
    ClientEditTabInformation,
  },
  setup() {
    const clientData = ref({});
    const projectData = ref([]);
    const contractData = ref({});
    const contracts = ref([]);
    const invoiceData = ref([]);
    const quotationData = ref([]);
    const invoiceOptions = ref([]);
    const paymentData = ref([]);
    const USER_APP_STORE_MODULE_NAME = "app-client";
    const userOptions = ref([]);
    const applicationOptions = ref([]);
    const projectstageOptions = ref([]);
    const paymentOptions = ref([]);
    const templateOptions = ref([]);
    const servicecodeOptions = ref([]);
    const receiptTemplateOptions = ref([]);
    const projectOptions = ref([]);
    const groupcodeOptions = ref([]);
    const clientOptions = ref([]);
    const documentTypeOptions = ref([]);
    const clientCode = ref("");
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, clientStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const refetchData = () => {
      store
        .dispatch(USER_APP_STORE_MODULE_NAME + "/fetchClient", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          clientData.value = response.data.client;
          clientCode.value = clientData.value.client_code;
          projectData.value = response.data.client.projects;
          projectOptions.value = projectData.value.filter(
            (item) => item.contract_id == null
          );
          invoiceData.value = response.data.client.invoices;
          invoiceOptions.value = invoiceData.value.filter(
            (item) => item.contract_id == null
          );
          contracts.value = response.data.client.contracts;
          quotationData.value = response.data.client.quotations;
          paymentData.value = response.data.client.invoice_payments;
          console.log(response.data.client.invoice_payments);
          contractData.value = {
            id: 0,
            client_id: clientData.value.id,
            contract_date: "",
            company_name_cn: clientData.value.company_name_cn,
            company_name_en: clientData.value.company_name_en,
            br_address: clientData.value.br_address,
            br_no: clientData.value.br_no,
            contacts: clientData.value.contacts,
            sale_id: clientData.value.sale_id,
            contact_name:
              clientData.value.contacts && clientData.value.contacts.length > 0
                ? clientData.value.contacts[0].contact_name
                : "",
            contact_title:
              clientData.value.contacts && clientData.value.contacts.length > 0
                ? clientData.value.contacts[0].contact_title
                : "",
            contact_phone:
              clientData.value.contacts && clientData.value.contacts.length > 0
                ? clientData.value.contacts[0].contact_phone
                : "",
            id_number:
              clientData.value.contacts && clientData.value.contacts.length > 0
                ? clientData.value.contacts[0].id_number
                : "",
          };
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            clientData.value = undefined;
          }
        });
    };

    store
      .dispatch(USER_APP_STORE_MODULE_NAME + "/fetchOptionList", {
        is_options: 1,
        client_id: router.currentRoute.params.id,
      })
      .then((response) => {
        userOptions.value = response.data.users;
        applicationOptions.value = response.data.applicationcodes;
        projectstageOptions.value = response.data.projectstagecodes;
        paymentOptions.value = response.data.paymentmethods;
        templateOptions.value = response.data.contracttemplates;
        groupcodeOptions.value = response.data.groupcodes;
        clientOptions.value = response.data.clients;
        servicecodeOptions.value = response.data.servicecodes;
        receiptTemplateOptions.value = response.data.receipt_templates || [];
        documentTypeOptions.value = response.data.document_types;

        //groupcodeOptions.value.unshift({id:null, group_code:' -- ', name_of_group: ''})
        //console.log('templateOptions', templateOptions.value)
        //  console.log('userOptions', userOptions.value)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userOptions.value = undefined;
        }
      });

    // Project Table Columns
    const { tableProjectColumns } = useProjectsList();

    // Invoice Table Columns
    const tableInvoiceColumns = [
      { key: "invoice_number", label: "發票編號", sortable: true },
      { key: "client", label: "客戶編號" },
      { key: "client_company_name_cn", label: "客戶名稱", sortable: true },
      { key: "contract.contract_code", label: "合約編號" },
      { key: "project.project_id", label: "項目編號" },
      { key: "user.name", label: "銷售顧問" },
      { key: "total_amount", label: "應付金額", sortable: true },
      { key: "total_paid", label: "已付金額", sortable: true },
      { key: "invoice", label: "發票" },
      { key: "is_paid", label: "已付款" },
      { key: "is_audit", label: "已審核" },
      { key: "actions", label: "操作" },
    ];

    const tableQuotationColumns = [
      { key: "quotation_number", label: "QUOTATION NUMBER", sortable: true },
      { key: "project", label: "PROJECT ID", sortable: true },
      { key: "total_amount", label: "AMOUNT(HK$)", sortable: true },
      { key: "quotation_date", label: "QUOTATION DATE", sortable: true },
      { key: "created_at", label: "CREATE DATE", sortable: true },
      { key: "export", label: "EXPORT", sortable: true },
    ];

    // Payment Table Columns
    const tablePaymentColumns = [
      { key: "invoice.invoice_number", label: "發票編號", sortable: true },
      { key: "client", label: "客戶編號" },
      { key: "invoice.client_company_name_cn", label: "客戶名稱" },
      { key: "user.name", label: "創建者" },
      //{key: 'item', label:'PAYMENT ITEM'},
      { key: "payment_amount", label: "付款金額(HK$)", sortable: true },
      { key: "payment_method.method_name", label: "付款方法" },
      { key: "payment_date", label: "付款日期", sortable: true },
      { key: "payment_remark", label: "付款備註" },
      { key: "document", label: "上傳/查閱收據" },
      //{key: 'invoice'},
      //{key: 'receipt'},
      { key: "is_audit", label: "已審核" },
      { key: "auditor.name", label: "審核者" },
      { key: "audit_remark", label: "內部備註" },
      { key: "actions", label: "操作" },
    ];

    const tableContractColumns = [
      { key: "contract_code", label: "合約編號", sortable: true },
      { key: "contract_name", label: "合約名稱" },
      { key: "contract_date", label: "合約日期" },
      { key: "contract", label: "合約", sortable: true },
      { key: "is_signed", label: "已簽署", sortable: true },
      { key: "signed_at", label: "簽署日期" },
      { key: "is_completed", label: "已完成", sortable: true },
      { key: "created_at", label: "生成日期", sortable: true },
      { key: "sale.name", label: "銷售顧問" },
      { key: "actions", label: "操作" },
    ];

    refetchData();

    return {
      refetchData,
      clientData,
      projectData,
      contractData,
      contracts,
      invoiceData,
      quotationData,
      invoiceOptions,
      paymentData,
      userOptions,
      applicationOptions,
      projectstageOptions,
      paymentOptions,
      receiptTemplateOptions,
      templateOptions,
      projectOptions,
      groupcodeOptions,
      tableProjectColumns,
      tableInvoiceColumns,
      tableQuotationColumns,
      tablePaymentColumns,
      tableContractColumns,
      clientCode,
      clientOptions,
      servicecodeOptions,
      documentTypeOptions,
    };
  },
};
</script>

<style scoped>
.modal-body {
  min-height: 80vh !important;
}
</style>
