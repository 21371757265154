<template>
  <div>
    <modal-document :id="modalId" :invoice_id="invoice_id" />

    <modal-payment 
      :is-add-new-payment-sidebar-active.sync="isAddNewPaymentSidebarActive"
      :id="id"
      :data="editData"
      :payment-options="paymentOptions"
      :receipt-template-options="receiptTemplateOptions"
      @refetch-data="refetchData"
      v-if="ability.can('update', 'payments')"
    />
   
    <b-card no-body class="mb-0">
      <b-table ref="refPaymentListTable" class="position-relative" :items="paymentData" responsive
        :fields="tablePaymentColumns" primary-key="id" show-empty empty-text="No matching records found">
        <template #cell(client)="data">
          <b-link :to="{
            name: 'clients-detail',
            params: { id: data.item.invoice.client.id },
          }">{{ data.item.invoice.client.client_code }}</b-link>
        </template>

        <template #cell(document)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
            @click="downloadDocument(data.item.invoice_id)">
            <feather-icon icon="DownloadCloudIcon" />
          </b-button>
        </template>

        <!-- Column: PAYMENT AMOUNT -->
        <template #cell(invoice)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon"
            :to="{ name: 'invoices-preview', params: { id: data.item.invoice_id } }">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>
        <!-- Column: PAYMENT REMARK -->
        <template #cell(payment_remark)="data">
          <div v-html="data.item.payment_remark"></div>
        </template>
        <template #cell(payment_amount)="data">
          {{ getNumberFormat(data.item.payment_amount) }}
        </template>
        <!-- Column: IS AUDIT -->
        <template #cell(is_audit)="data">
          <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('delete', 'payments') && data.item.deleted_at == null"
              @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import ModalDocument from "@/views/payment/modal/ModalDocument.vue";
import ability from '@/libs/acl/ability'
import paymentStoreModule from "@/views/payment/paymentStoreModule";
import ModalPayment from "@/views/payment/modal/ModalPayment.vue";

export default {
  data() {
    return {
      id: 0,
      modalId: '-client-payment-list',
      invoice_id: null,
      ability,
      editData: {},
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
    quillEditor,
    BFormDatepicker,
    ModalDocument,
    ModalPayment
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("payment/deletePayment", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    downloadDocument(invoice_id) {
      this.invoice_id = invoice_id
      this.$bvModal.show(`modal-document${this.modalId}`);
    },
    toggleUpload() {
      this.showUpload = !this.showUpload
    },
    beforeRemove(file) {
      if (file.url == process.env.VUE_APP_IMAGE_URL + this.payment.image_path) {
        this.removeExistingPic = true
        this.payment.image_path = null
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload
      this.payment.file = file.raw
    },
  
    hide() {
      this.isAddNewPaymentSidebarActive = false;
    },
    resetForm() {
      this.payment = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    handleEdit(item) {
      this.editData = {...item}
      this.id = item.id;
      this.isAddNewPaymentSidebarActive = true;
    },
  

    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    refetchData(){
      this.$emit("refetch-data");
    }
  },
  props: {
    paymentData: {
      type: Array,
      required: true,
    },
    tablePaymentColumns: {
      type: Array,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
    receiptTemplateOptions:{
      type: Array,
      required: true
    }
  },

  setup() {
    const PAYMENT_STORE_MODULE_NAME = "payment";

    if (!store.hasModule(PAYMENT_STORE_MODULE_NAME))
      store.registerModule(PAYMENT_STORE_MODULE_NAME, paymentStoreModule);

    const showUpload = ref(true)
    const isSortDirDesc = ref(true);
    const isAddNewPaymentSidebarActive = ref(false);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + '/payment');
    const headerObj = { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }
    const fileList = ref([])
    const payment = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });

    const loading = ref(false);

    const blankPaymentData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };
    const button_text = ref("Save");

    return {
      isSortDirDesc,
      isAddNewPaymentSidebarActive,
      payment,
      button_text,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  background-color: $primary;
  opacity: 0.8;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;

}

.hideUpload>div {
  display: none;
}
</style>
